import { useContext, useMemo } from 'react'

import {
  type SanityFilter,
  type SanityFilterGroup,
  type SanitySortOptionType,
} from '@data/sanity/queries/types/cart'
import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import {
  compareNumbers,
  compareStrings,
  escapeRegExp,
  getAllCombinations,
} from '@lib/helpers'
import { generateSanityKey } from '@lib/nanoid'
import { type UrlParameter } from '@lib/parameters'
import { SearchContext } from '@lib/search-context'
import { type Filter } from './types'

/**
 * Returns a method that compares products for sorting by price.
 */
const sortProductsByPrice = (isDescending = false) => {
  const sign = isDescending ? -1 : 1

  return (product1: SanityProductFragment, product2: SanityProductFragment) =>
    sign * compareNumbers(product1.price ?? 0, product2.price ?? 0)
}

/**
 * Returns a method that compares products for sorting by title.
 */
const sortProductsByTitle = (isDescending = false) => {
  const sign = isDescending ? -1 : 1

  return (
    { title: title1 }: SanityProductFragment,
    { title: title2 }: SanityProductFragment
  ) => sign * compareStrings(title1, title2)
}

/**
 * Returns a method that compares products for sorting by creation date.
 */
const sortProductsByDate = (isDescending = false) => {
  const sign = isDescending ? -1 : 1

  return (
    { _createdAt: createdAt1 }: SanityProductFragment,
    { _createdAt: createdAt2 }: SanityProductFragment
  ) => sign * compareStrings(createdAt1, createdAt2)
}

/**
 * Collection filter and sort hook.
 */
export const useFilterAndSort = (
  products: SanityProductFragment[],
  featuredProductIds: number[],
  filters: Filter[],
  sortValue: SanitySortOptionType | null
) => {
  const filteredProducts = useMemo(() => {
    const filterCombinations = getAllCombinations(
      ...filters
        .filter((filter) => filter.name !== 'query')
        .filter((filter) => filter.values.length > 0)
        .map((filter) => filter.values)
    )
    const searchQueries = filters
      .filter((filter) => filter.name === 'query')
      .map((filter) => filter.values)
      .flat()

    return (
      products
        // Keep or discard products based on filters
        .filter((product) => {
          const productFilterValues =
            product.filters?.map((filter) => filter.slug.current) ?? []

          return filterCombinations.some((filterCombination) =>
            filterCombination.every((filterCombinationValue) =>
              productFilterValues.includes(filterCombinationValue)
            )
          )
        })
        // Keep or discard products based on search queries
        .filter((product) => {
          if (searchQueries.length === 0) {
            return true
          }

          // Match product title, description or variant SKU
          return searchQueries.every((searchQuery) => {
            const searchQueryRegex = new RegExp(
              `${escapeRegExp(searchQuery)}`,
              'gi'
            )
            const variantSkus = (product.variants
              ?.map((variant) => variant.sku)
              ?.filter(Boolean) ?? []) as string[]

            return (
              (product.title && searchQueryRegex.test(product.title)) ||
              (product.descriptionText &&
                searchQueryRegex.test(product.descriptionText)) ||
              variantSkus.some((sku) => searchQueryRegex.test(sku))
            )
          })
        })
    )
  }, [filters, products])

  switch (sortValue) {
    case 'priceAsc': {
      return filteredProducts.sort(sortProductsByPrice())
    }

    case 'priceDesc': {
      return filteredProducts.sort(sortProductsByPrice(true))
    }

    case 'alphaAsc': {
      return filteredProducts.sort(sortProductsByTitle())
    }

    case 'alphaDesc': {
      return filteredProducts.sort(sortProductsByTitle(true))
    }

    case 'dateAsc': {
      return filteredProducts.sort(sortProductsByDate())
    }

    case 'dateDesc': {
      return filteredProducts.sort(sortProductsByDate(true))
    }

    case 'featured': {
      const productIndices = featuredProductIds.reduce(
        (result: Record<number, number>, value: number, index: number) => (
          (result[value] = index + 1), result
        ),
        {}
      )

      return filteredProducts.sort(
        (product1: SanityProductFragment, product2: SanityProductFragment) =>
          (productIndices[product1.productID] ?? Infinity) -
          (productIndices[product2.productID] ?? Infinity)
      )
    }

    default: {
      return filteredProducts
    }
  }
}

/**
 * Gets sort parameter value from URL parameters.
 */
export const getSortParameterValue = (activeParameters: UrlParameter[]) => {
  const sortParameter = activeParameters.find(
    (activeParameter) => activeParameter.name === 'sort'
  )
  const value = sortParameter?.value

  if (!value || Array.isArray(value)) {
    return null
  }

  return value as SanitySortOptionType
}

/**
 * Returns a method that gets filter from a URL parameter.
 */
export const getFilterFromUrlParameter =
  (filterGroups: SanityFilterGroup[]) =>
  (activeParameter: UrlParameter): Filter => {
    const filterGroup = filterGroups.find(
      (filterGroup) => filterGroup.slug.current === activeParameter.name
    )
    const optionSlugs =
      filterGroup?.options?.map((option) => option.slug.current) ?? []
    const values =
      activeParameter.value && Array.isArray(activeParameter.value)
        ? activeParameter.value
        : activeParameter.value?.split(',') ?? []

    return {
      name: activeParameter.name,
      values: [
        ...new Set(
          values.filter((value) => value && optionSlugs.includes(value))
        ),
      ],
    }
  }

/**
 * Gets filter slugs for product.
 */
const getProductFilterSlugs = (products: SanityProductFragment[]) => {
  const slugs = new Set()

  products.forEach((product) => {
    if (product.filters) {
      product.filters.forEach((filter) => {
        const slug = filter.slug ? filter.slug.current : null

        if (slug) {
          slugs.add(slug)
        }
      })
    }
  })

  return Array.from(slugs)
}

/**
 * Gets parsed filter groups helper hook.
 */
export const useFilterGroups = (
  products: SanityProductFragment[],
  filter?: SanityFilter
) => {
  const { queryUrlParameterValue } = useContext(SearchContext)

  return useMemo(() => {
    const productFilterSlugs = getProductFilterSlugs(products)
    const filterGroups: SanityFilterGroup[] = []

    if (queryUrlParameterValue) {
      // Prepend search query filter to other filters
      filterGroups.push({
        _key: generateSanityKey(),
        slug: {
          current: 'query',
        },
        title: 'Search',
        options: [
          {
            type: ' ',
            slug: {
              current: queryUrlParameterValue,
            },
            title: queryUrlParameterValue,
          },
        ],
      })
    }

    filter?.groups?.forEach((group) => {
      // Exclude filter group options that don't match any product filter slug
      const options = group.options.filter((option) =>
        productFilterSlugs.includes(option.slug.current)
      )

      filterGroups.push({
        ...group,
        options,
      })
    })

    return filterGroups.filter((group) => group.options.length > 0)
  }, [filter?.groups, products, queryUrlParameterValue])
}
