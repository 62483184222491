import cx from 'classnames'
import { contrastColor } from 'contrast-color'
import { type CSSProperties, type ReactNode } from 'react'

import { type SanityColor } from '@data/sanity/queries/types/color'

import { colors } from '@lib/theme'

interface CSSPropertiesWithSwatch extends CSSProperties {
  '--swatchColor': string
  '--swatchBorder': string
}

interface SwatchProps {
  color: SanityColor
  label: string
  isActive?: boolean
  isCrossed?: boolean
  className?: string
  children?: ReactNode
}

const Swatch = ({ color, label, isActive, className }: SwatchProps) => {
  const borderColor = color.hex ? contrastColor({ bgColor: color.hex }) : ''

  const style: CSSPropertiesWithSwatch = {
    '--swatchColor': color.hex,
    '--swatchBorder': borderColor,
  }

  return (
    <div
      className={cx(
        'relative inline-flex w-5 h-5 border border-gray-300 overflow-hidden',
        isActive ? 'border-2 border-pageText' : 'border-[#D1D5DB]',
        className
      )}
      role="button"
      aria-label={label}
    >
      <div
        style={{ ...style, backgroundColor: 'var(--swatchColor)' }}
        className={cx('absolute inset-0 m-0.5', {
          'border border-pageText': color.hex === colors.white.toLowerCase(),
        })}
      />
    </div>
  )
}

export default Swatch
