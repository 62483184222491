import { type SanityFilterGroup } from '@data/sanity/queries/types/cart'
import { type UrlParameter } from '@lib/parameters'
import { type Filter } from '@lib/product/types'

import Accordion from '@components/accordion'
import CollectionFilterOption from './filter-option'

interface CollectionFilterGroupProps {
  group: SanityFilterGroup
  onChange: (newParameters: UrlParameter[]) => void
  activeFilter?: Filter
}

const CollectionFilterGroup = ({
  group,
  onChange,
  activeFilter,
}: CollectionFilterGroupProps) => {
  const selectedValueCount = activeFilter?.values?.length ?? 0
  const isSearch = group.slug.current === 'query'

  if (isSearch) {
    return null
  }

  return (
    <Accordion
      id={group._key}
      title={
        <span id={`filter-group-${group._key}`}>
          {group.title}

          {selectedValueCount > 0 && (
            <span className="opacity-50 600 ml-2 text-xs">
              ({selectedValueCount})
            </span>
          )}
        </span>
      }
      defaultIsOpen={true}
      className="first:border-t-0 border-divider"
      headerClassName="text-sm font-medium"
    >
      <div
        role="group"
        aria-labelledby={`filter-group-${group._key}`}
        className="flex flex-col gap-3"
      >
        {group.options.map((option, index) => (
          <CollectionFilterOption
            key={index}
            group={group}
            option={option}
            activeFilter={activeFilter}
            onChange={onChange}
          />
        ))}
      </div>
    </Accordion>
  )
}

export default CollectionFilterGroup
