import cx from 'classnames'
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import {
  type SanityFilter,
  type SanitySort,
} from '@data/sanity/queries/types/cart'
import { type SanityCollectionStrings } from '@data/sanity/queries/types/modules'
import { type SanityProductFragment } from '@data/sanity/queries/types/product'
import { clampRange } from '@lib/helpers'
import { usePrevious } from '@lib/hooks'
import { type UrlParameter, useUrlParameters } from '@lib/parameters'
import {
  getFilterFromUrlParameter,
  getSortParameterValue,
  useFilterAndSort,
  useFilterGroups,
} from '@lib/product/collection'
import { getSortedProducts } from '@lib/product/product'
import { parseOptionalParameter } from '@lib/request'
import { SiteContext } from '@lib/site-context'
import { StringsContext } from '@lib/strings-context'

import Button, { ButtonVariant } from '@components/buttons/button'
import ProductCard from '@blocks/shop/product-card'
import CollectionFilter from './filter'
import CollectionSort from './sort'
import ComplexPortableText from '@components/complex-portable-text'
import CollectionFilterDrawer from './filter-drawer'


export enum CollectionViewType {
  ALL_PRODUCTS = 'shop',
  COLLECTION = 'collection',
  PRODUCT_CATEGORY = 'productCategory',
}

interface CollectionProps {
  collectionStrings: SanityCollectionStrings
  products: SanityProductFragment[]
  productIds?: string[]
  featuredProductIds: number[]
  paginationLimit: number
  title?: string
  sort?: SanitySort
  filter?: SanityFilter
  viewType: CollectionViewType
}

/**
 * The collection component.
 */
const Collection = ({
  collectionStrings,
  products,
  productIds,
  featuredProductIds,
  paginationLimit,
  title,
  sort,
  filter,
  viewType
}: CollectionProps) => {
  viewType = viewType || CollectionViewType.ALL_PRODUCTS

  const { isRouteChanging } = useContext(SiteContext)
  const strings = useContext(StringsContext)

  const parsedProducts = useMemo(() => {
    const sortedProducts = productIds
      ? getSortedProducts(products, productIds)
      : products
    return sortedProducts
  }, [productIds, products])

  const [hasPagination, setHasPagination] = useState(
    paginationLimit > 0 && parsedProducts.length > paginationLimit
  )
  const [currentCount, setCurrentCount] = useState(
    hasPagination ? paginationLimit : parsedProducts.length
  )

  const collectionItems = useRef<(HTMLDivElement | null)[]>([])

  const filterGroups = useFilterGroups(parsedProducts, filter)

  // Manage URL parameters
  const defaultSortOption = sort?.options?.[0]?.type ?? null
  const [currentParameters, setCurrentParameters] = useUrlParameters([
    {
      name: 'page',
      value: null,
    },
    {
      name: 'sort',
      value: defaultSortOption,
    },
    ...filterGroups.map(({ slug }) => ({
      name: slug.current,
      value: null,
    })),
  ])
  const previousParameters = usePrevious(currentParameters)
  const activeParameters = useMemo(
    () =>
      isRouteChanging && previousParameters
        ? previousParameters
        : currentParameters,
    [currentParameters, isRouteChanging, previousParameters]
  )

  // Manage sorting value
  const activeSortValue = getSortParameterValue(activeParameters)

  // Manage filter values
  const activeFilters = useMemo(
    () =>
      activeParameters
        .filter(
          (activeParameter) => !['page', 'sort'].includes(activeParameter.name)
        )
        .map(getFilterFromUrlParameter(filterGroups)),
    [activeParameters, filterGroups]
  )
  const activeFilterValueCount = useMemo(
    () =>
      activeFilters.reduce(
        (total, activeFilter) => total + activeFilter.values.length,
        0
      ),
    [activeFilters]
  )

  // Manage sorting and pagination
  const processedProducts = useFilterAndSort(
    parsedProducts,
    featuredProductIds,
    activeFilters,
    // Use default sort option as fallback, since when sort option value matches the default it's empty
    activeSortValue ?? defaultSortOption
  )
  const paginatedProducts = useMemo(
    () => [...processedProducts.slice(0, currentCount)],
    [currentCount, processedProducts]
  )

  // Manage product pagination
  useEffect(() => {
    const pageParameter = activeParameters.find(
      (activeParameter) => activeParameter.name === 'page'
    )
    const page = Number(parseOptionalParameter(pageParameter?.value) ?? 1)
    const productCount = processedProducts.length
    const pageCount = Math.ceil(productCount / paginationLimit)

    if (page <= 1 || page > pageCount) {
      return
    }

    const newCount = clampRange(paginationLimit * page, 1, productCount)

    if (newCount !== currentCount) {
      setCurrentCount(newCount)
    }
  }, [currentCount, processedProducts, activeParameters, paginationLimit])

  // Focus on the first link in the first or last product card
  useEffect(() => {
    const pageProductIndex =
      currentCount < processedProducts.length
        ? currentCount - paginationLimit
        : processedProducts.length - 1
    collectionItems.current[pageProductIndex]
      ?.querySelector<HTMLAnchorElement>('[href]')
      ?.focus({
        preventScroll: true,
      })
  }, [currentCount, paginationLimit, processedProducts.length])

  // Update pagination status
  useEffect(
    () => setHasPagination(currentCount < processedProducts.length),
    [currentCount, processedProducts.length]
  )

  // Handle filter and sort updates
  const updateParameters = useCallback(
    (urlParameters: UrlParameter[]) => {
      const newUrlParameters: UrlParameter[] = activeParameters.map(
        (activeParameter) => {
          const matchedParameter = urlParameters.find(
            (urlParameter) => urlParameter.name === activeParameter.name
          )

          return matchedParameter
            ? {
                ...activeParameter,
                value: matchedParameter?.value ?? null,
              }
            : activeParameter
        }
      )

      setCurrentParameters(newUrlParameters)
    },
    [activeParameters, setCurrentParameters]
  )

  // Handle loading more products
  const loadMore = useCallback(() => {
    const newCount = clampRange(
      currentCount + paginationLimit,
      1,
      processedProducts.length
    )

    const newPage = Math.ceil(newCount / paginationLimit)

    setCurrentCount(newCount)
    updateParameters([
      {
        name: 'page',
        value: newPage > 1 ? `${newPage}` : null,
      },
    ])
  }, [currentCount, processedProducts, paginationLimit, updateParameters])

  const clearFilters = useCallback(
    () =>
      updateParameters(
        activeFilters.map((activeFilter) => ({
          name: activeFilter.name,
          value: null,
        }))
      ),
    [activeFilters, updateParameters]
  )

  const productCountText = useMemo(() => {
    if (viewType === CollectionViewType.ALL_PRODUCTS) {
      return collectionStrings.collectionAllProducts?.replace(
        /{count}/gi,
        `${processedProducts.length}`
      );
    }

    return `(${processedProducts.length} ${
      collectionStrings.collectionProducts?.replace(/{title}/gi, `${title}`)
    })`;
  }, [viewType, collectionStrings, processedProducts.length, title]);

  if (parsedProducts.length === 0) {
    return null
  }

  return (
    <section>
      <div className="container">
        <div className="py-10 space-y-1.5">
          <h1 className="is-h2">{title}</h1>
          <p className="text-gray-700">
            {productCountText}
          </p>
        </div>

        <div className="flex justify-between md:justify-start items-center py-3 md:py-5 border-t">
          <CollectionFilterDrawer
            collectionStrings={collectionStrings}
            filterGroups={filterGroups}
            activeFilters={activeFilters}
            activeFilterValueCount={activeFilterValueCount}
            itemTotal={processedProducts.length}
            onChange={updateParameters}
          />

          {sort?.isActive && (
            <CollectionSort
              collectionStrings={collectionStrings}
              sortOptions={sort.options ?? []}
              activeSortValue={activeSortValue}
              onChange={updateParameters}
            />
          )}
        </div>

        <div className="grid md:grid-cols-4 md:py-10 border-t">
          {filter?.isActive && (
            <CollectionFilter
              collectionStrings={collectionStrings}
              filterGroups={filterGroups}
              activeFilters={activeFilters}
              activeFilterValueCount={activeFilterValueCount}
              itemTotal={processedProducts.length}
              onChange={updateParameters}
              className="hidden md:block md:col-span-1 max-w-80"
            />
          )}

          <div
            className={cx(
              'col-span-3 grid',
              processedProducts.length > 0
                ? 'grid-cols-2 lg:grid-cols-3 gap-5 sm:gap-8'
                : 'grid-cols-1'
            )}
          >
            {paginatedProducts.map((product, index) => {
              const productIdKey = product.productID
              const activeParameterKey = activeParameters
                .filter((activeParameter) => activeParameter.name !== 'page')
                .map((activeParameter) => activeParameter.value)
                .filter(Boolean)
                .join('-')

              return (
                <ProductCard
                  key={`${productIdKey}-${activeParameterKey}`}
                  ref={(node) => {
                    collectionItems.current[index] = node
                  }}
                  product={product}
                  activeFilters={activeFilters}
                  showPrice
                  showQuickAdd
                />
              )
            })}

            <div
              className={cx(
                processedProducts.length > 0
                  ? 'col-span-2 lg:col-span-3'
                  : 'col-span-1'
              )}
            >
              {processedProducts.length === 0 && (
                <div className="px-8 py-16 min-h-[35vh] text-center">
                  <ComplexPortableText
                    content={collectionStrings.collectionFilterNoResults}
                  />
                  <Button
                    variant={ButtonVariant.OUTLINED}
                    onClick={clearFilters}
                    className="mt-8"
                  >
                    {collectionStrings.collectionClearFiltersLabel}
                  </Button>
                </div>
              )}

              {hasPagination && (
                <div className="relative py-4 sm:py-8 text-center">
                  <Button variant={ButtonVariant.FILLED} onClick={loadMore}>
                    {strings.buttonLoadMore}
                    <span className="sr-only">
                      {collectionStrings.collectionProducts?.replace(
                        /{title}/gi,
                        title ? `&quot;${title}&quot;` : ''
                      )}
                    </span>
                  </Button>
                </div>
              )}

              {processedProducts.length > 0 && (
                <div className="relative py-4 sm:py-8 text-center">
                  <p aria-live="polite" role="status" aria-atomic="true">
                    {collectionStrings.collectionProductCount
                      ?.replace(/{count}/gi, `${paginatedProducts.length}`)
                      ?.replace(/{total}/gi, `${processedProducts.length}`)}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Collection
