import cx from 'classnames'
import FocusTrap from 'focus-trap-react'
import { motion } from 'framer-motion'
import { type ReactNode, useEffect, useRef, useState } from 'react'

import { fontInter } from '@lib/fonts'

import InPortal from './in-portal'
import DrawerBackdrop from './backdrop'

export enum DrawerDirection {
  LEFT = 'left',
  RIGHT = 'right',
}

interface DrawerProps {
  isOpen: boolean
  onClose: (isOpen: boolean) => void
  direction?: DrawerDirection
  className?: string
  children: ReactNode
}

const Drawer = ({
  isOpen,
  onClose,
  direction = DrawerDirection.RIGHT,
  className,
  children,
}: DrawerProps) => {
  const drawerRef = useRef<HTMLElement | null>(null)
  const [isActive, setIsActive] = useState(isOpen)
  const [hasFocus, setHasFocus] = useState(isOpen)

  useEffect(() => setIsActive(isOpen), [isOpen])

  // Handle escape key press
  useEffect(() => {
    function handleKeyDown(event: KeyboardEvent) {
      if (event.key === 'Escape') {
        onClose(false)
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [onClose])

  return (
    <InPortal id="drawer">
      <>
        <FocusTrap
          active={isActive && hasFocus}
          focusTrapOptions={{
            fallbackFocus: drawerRef.current ?? undefined,
            allowOutsideClick: true,
          }}
        >
          <motion.nav
            ref={drawerRef}
            key="drawer"
            initial="hide"
            animate={isActive ? 'show' : 'hide'}
            variants={{
              show: { x: '0%' },
              hide: {
                x: direction === DrawerDirection.RIGHT ? '100%' : '-100%',
              },
            }}
            transition={{ duration: 0.6, ease: [0.16, 1, 0.3, 1] }}
            onAnimationComplete={() => setHasFocus(isActive)}
            className={cx(
              'fixed top-0 w-full h-screen max-w-3xl z-90 bg-pageBG pointer-events-none transition-visibility',
              {
                'right-0': direction === DrawerDirection.RIGHT,
                'left-0': direction === DrawerDirection.LEFT,
                invisible: !isActive,
                'visible pointer-events-auto transition delay-[0s]': isActive,
              },
              fontInter.className,
              className
            )}
          >
            <div className="flex flex-col relative h-full w-full">
              {children}
            </div>
          </motion.nav>
        </FocusTrap>

        <DrawerBackdrop isOpen={isOpen} onClick={() => onClose(false)} />
      </>
    </InPortal>
  )
}

export default Drawer
