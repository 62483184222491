import { useState } from 'react'

import CollectionFilter, { type CollectionFilterProps } from './filter'

import Button, {
  ButtonVariant,
  ButtonSize,
  ButtonColor,
} from '@components/buttons/button'
import Drawer, { DrawerDirection } from '@components/drawer'
import Icon from '@components/icon'

const CollectionFilterDrawer = ({
  collectionStrings,
  filterGroups,
  activeFilters = [],
  activeFilterValueCount,
  itemTotal,
  onChange,
}: CollectionFilterProps) => {
  const [isOpen, setIsOpen] = useState(false)

  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)

  return (
    <div>
      <Button
        className="inline-flex md:hidden"
        variant={ButtonVariant.OUTLINED}
        color={ButtonColor.GRAY}
        size={ButtonSize.SMALL}
        onClick={open}
      >
        {collectionStrings.collectionShowFiltersLabel}
      </Button>

      <Drawer
        direction={DrawerDirection.LEFT}
        isOpen={isOpen}
        onClose={close}
        className="max-w-md sm:max-w-sm"
      >
        <div className="border-b p-5 flex justify-between gap-5">
          <h3 className="is-h4">{collectionStrings.collectionFilters}</h3>
          <button className="text-lg" onClick={close}>
            <Icon name="Close" id="close-filter-drawer-icon" />
          </button>
        </div>

        <CollectionFilter
          className="px-5 overflow-y-auto grow"
          collectionStrings={collectionStrings}
          filterGroups={filterGroups}
          activeFilters={activeFilters}
          activeFilterValueCount={activeFilterValueCount}
          itemTotal={itemTotal}
          onChange={onChange}
        />

        <div className="p-5 shadow-[0px_0px_20px_0px_rgba(0,0,0,0.15)]">
          <Button
            variant={ButtonVariant.FILLED}
            disabled={itemTotal === 0}
            onClick={close}
            className="w-full"
          >
            {itemTotal > 0
              ? collectionStrings.collectionFilterResultsLabel?.replace(
                  /{count}/gi,
                  `${itemTotal}`
                )
              : collectionStrings.collectionFilterNoResultsLabel}
          </Button>
        </div>
      </Drawer>
    </div>
  )
}

export default CollectionFilterDrawer
