import { type ReactNode, useEffect, useRef, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import FocusTrap from 'focus-trap-react'
import cx from 'classnames'

import { type UrlParameter } from '@lib/parameters'

import RadioGroup from '@components/radio-group'
import RadioItem from '@components/radio-item'

interface ListOption {
  type: string
  title: string
}

interface CollectionListboxProps {
  id: string
  name: string
  label: string
  options: ListOption[]
  activeOption: string | null
  before?: ReactNode
  after?: ReactNode
  onChange?: (newParameters: UrlParameter[]) => void
  className?: string
}

const CollectionListbox = ({
  id,
  name,
  label,
  options,
  activeOption,
  before,
  after,
  onChange = () => null,
  className,
}: CollectionListboxProps) => {
  const groupRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)

  const currentOption =
    options.find((option) => option.type === activeOption) ?? options[0]

  const handleChange = (value: string) => {
    onChange([{ name, value }])
    setIsOpen(false)
  }

  function onOutsideClick(event: MouseEvent) {
    if (event.target && groupRef.current?.contains(event.target as Element)) {
      return
    }

    setIsOpen(false)
  }

  function handleKeyDown(event: KeyboardEvent) {
    let flag = false

    switch (event.code) {
      case 'Escape': {
        setIsOpen(false)
        flag = true
        break
      }

      default: {
        break
      }
    }

    if (flag) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', onOutsideClick)

    return () => {
      document.removeEventListener('mousedown', onOutsideClick)
    }
  }, [])

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [isOpen])

  return (
    <div ref={groupRef} className={cx('relative z-20', className)}>
      <span id={`${id}-label`} className="sr-only">
        {label}
      </span>

      <button
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
        aria-labelledby={`${id}-label`}
        className="inline-flex gap-x-1.5 text-sm"
      >
        {before}
        {currentOption?.title}
        {after}
      </button>

      <AnimatePresence initial={false}>
        {isOpen && (
          <FocusTrap
            focusTrapOptions={{
              clickOutsideDeactivates: true,
              preventScroll: true,
            }}
          >
            <div className="absolute top-full mt-2 overflow-hidden left-0">
              <motion.div
                initial="hide"
                animate="show"
                exit="hide"
                variants={{
                  show: {
                    y: '0%',
                  },
                  hide: {
                    y: '-120%',
                  },
                }}
                transition={{ duration: 0.6, ease: [0.16, 1, 0.3, 1] }}
                className="bg-pageBG overflow-hidden whitespace-nowrap border"
              >
                <RadioGroup
                  value={currentOption.type}
                  onChange={(value) => handleChange(value)}
                  role="listbox"
                  className="flex flex-col p-2"
                >
                  {options.map((option, index) => (
                    <RadioItem
                      key={index}
                      value={option.type}
                      role="option"
                      aria-selected={
                        option.type === currentOption.type ? 'true' : 'false'
                      }
                      className={cx(
                        'text-pageText px-2 py-1 text-sm text-left',
                        'hover:opacity-50'
                      )}
                    >
                      {option.title}
                    </RadioItem>
                  ))}
                </RadioGroup>
              </motion.div>
            </div>
          </FocusTrap>
        )}
      </AnimatePresence>
    </div>
  )
}

export default CollectionListbox
