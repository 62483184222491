import {
  type SanitySortOption,
  type SanitySortOptionType,
} from '@data/sanity/queries/types/cart'
import { type SanityCollectionStrings } from '@data/sanity/queries/types/modules'
import { type UrlParameter } from '@lib/parameters'

import CollectionListbox from './listbox'
import Icon from '@components/icon'

interface CollectionSortProps {
  collectionStrings: SanityCollectionStrings
  sortOptions: SanitySortOption[]
  activeSortValue: SanitySortOptionType | null
  onChange: (newParameters: UrlParameter[]) => void
}

const CollectionSort = ({
  collectionStrings,
  sortOptions,
  activeSortValue,
  onChange,
}: CollectionSortProps) => (
  <CollectionListbox
    id="collection-sort"
    name="sort"
    label={collectionStrings.collectionSortDescription}
    options={sortOptions}
    activeOption={activeSortValue}
    onChange={onChange}
    before={<b>{collectionStrings.collectionSortLabel}</b>}
    after={
      <Icon name="ChevronDown" id="collection-sort-icon" className="text-lg" />
    }
    className="flex"
  />
)

export default CollectionSort
