import { type SanityFilterGroup } from '@data/sanity/queries/types/cart'
import { type SanityCollectionStrings } from '@data/sanity/queries/types/modules'
import { type UrlParameter } from '@lib/parameters'
import { type Filter } from '@lib/product/types'

import CollectionFilterGroup from './filter-group'
import CollectionFilterChips from './filter-chips'

export interface CollectionFilterProps {
  collectionStrings: SanityCollectionStrings
  filterGroups: SanityFilterGroup[]
  activeFilters: Filter[]
  activeFilterValueCount: number
  itemTotal: number
  className?: string
  onChange: (newParameters: UrlParameter[]) => void
}

const CollectionFilter = ({
  collectionStrings,
  filterGroups,
  activeFilters = [],
  activeFilterValueCount,
  className,
  onChange,
}: CollectionFilterProps) => {
  const clearFilters = () => {
    const newUrlParameters: UrlParameter[] = activeFilters.map(
      (activeFilter) => ({
        name: activeFilter.name,
        value: null,
      })
    )
    onChange(newUrlParameters)
  }

  return (
    <div className={className}>
      <CollectionFilterChips
        collectionStrings={collectionStrings}
        filterGroups={filterGroups}
        activeFilters={activeFilters}
        activeFilterValueCount={activeFilterValueCount}
        onClick={onChange}
        onClearClick={clearFilters}
      />

      {filterGroups.map((group) => (
        <CollectionFilterGroup
          key={group._key}
          group={group}
          activeFilter={activeFilters.find(
            (activeFilter) => activeFilter.name === group.slug.current
          )}
          onChange={onChange}
        />
      ))}
    </div>
  )
}

export default CollectionFilter
