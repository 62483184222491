import { PortableText } from '@portabletext/react'
import {
  type ArbitraryTypedObject,
  type PortableTextBlock,
} from '@portabletext/types'

import AddToCartButton from './portable-text/add-to-cart-button'
import ColoredText from './portable-text/colored-text'
import Figure from './portable-text/figure'
import HorizontalRule from './portable-text/horizontal-rule'
import InfoBox from './portable-text/info-box'
import Link from './portable-text/link'
import LottieAnimation from './portable-text/lottie-animation'
import Quote from './portable-text/quote'
import Table from './portable-text/table'
import VideoButton from './portable-text/video-button'
import VideoEmbed from './portable-text/video-embed'
import CheckmarkListItem from '@components/portable-text/checkmarkListItem'

type ComplexPortableTextBlock = PortableTextBlock | ArbitraryTypedObject

interface ComplexPortableTextProps {
  content: ComplexPortableTextBlock[]
  className?: string
}

const ComplexPortableText = ({
  content,
  className,
}: ComplexPortableTextProps) => {
  return (
    <div className={className}>
      <PortableText
        value={content}
        components={{
          block: {
            none: ({ children }) => <>{children}</>,
            normal: ({ children }) => <p>{children}</p>,
            small: ({ children }) => <p className="is-small">{children}</p>,
            large: ({ children }) => <p className="is-large">{children}</p>,
            h1: ({ children }) => <h1>{children}</h1>,
            h2: ({ children }) => <h2>{children}</h2>,
            h3: ({ children }) => <h3>{children}</h3>,
            h4: ({ children }) => <h4>{children}</h4>,
            h5: ({ children }) => <h5>{children}</h5>,
            h1mock: ({ children }) => <p className="is-h1">{children}</p>,
            h2mock: ({ children }) => <p className="is-h2">{children}</p>,
            h3mock: ({ children }) => <p className="is-h3">{children}</p>,
            h4mock: ({ children }) => <p className="is-h4">{children}</p>,
            h5mock: ({ children }) => <p className="is-h5">{children}</p>,
          },
          types: {
            addToCartButton: AddToCartButton,
            figure: Figure,
            horizontalRule: HorizontalRule,
            infoBox: InfoBox,
            lottieAnimation: LottieAnimation,
            quote: Quote,
            table: Table,
            videoButton: VideoButton,
            videoEmbed: VideoEmbed,
          },
          marks: {
            link: Link,
            textColor: ColoredText,
          },
          list: {
            bullet: ({ children }) => <ul className="list-disc ml-5">{children}</ul>,
            checkmark: ({ children }) => <ul className="list-checkmark !pl-0">{children}</ul>,
          },
          listItem: {
            bullet: ({ children }) => <li>{children}</li>,
            checkmark: CheckmarkListItem
          },
        }}
      />
    </div>
  )
}

export default ComplexPortableText
