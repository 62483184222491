import { customAlphabet } from 'nanoid'

/**
 * Generates a random Sanity key.
 */
export const generateSanityKey = () => {
  const nanoid = customAlphabet('1234567890abcdef', 16)
  return nanoid()
}

/**
 * Gets a custom logger.
 */
export const getCustomLogger = (prefix: string) => {
  const nanoid = customAlphabet('1234567890abcdef', 8)
  const requestId = nanoid()

  return (message?: unknown) => {
    console.log(
      `${prefix} req:${requestId} ${new Date().toISOString()} ${message}`
    )
  }
}
