import { useCallback, useMemo } from 'react'

import { type SanityFilterGroup } from '@data/sanity/queries/types/cart'
import { type SanityCollectionStrings } from '@data/sanity/queries/types/modules'
import { type UrlParameter } from '@lib/parameters'
import { type Filter, type FilterValue } from '@lib/product/types'

import Icon from '@components/icon'

interface CollectionFilterChipValueProps {
  collectionStrings: SanityCollectionStrings
  filterGroups: SanityFilterGroup[]
  activeFilters: Filter[]
  filterValue: FilterValue
  onClick: (newParameters: UrlParameter[]) => void
}

const CollectionFilterChipValue = ({
  collectionStrings,
  filterGroups,
  activeFilters,
  filterValue,
  onClick,
}: CollectionFilterChipValueProps) => {
  const newValues = useMemo(() => {
    const activeFilter = activeFilters.find(
      (activeFilter) => activeFilter.name === filterValue.name
    )
    const currentValues = activeFilter?.values ?? []

    return currentValues.filter((value) => value !== filterValue.value).join()
  }, [activeFilters, filterValue])

  const handleClick = useCallback(() => {
    onClick([
      {
        name: filterValue.name,
        value: newValues || null,
      },
    ])
  }, [filterValue, newValues, onClick])

  const filterGroup = filterGroups.find(
    (filterGroup) => filterGroup.slug.current === filterValue.name
  )
  const option = filterGroup?.options?.find(
    (option) => option.slug.current === filterValue.value
  )

  if (!option) {
    return null
  }

  return (
    <button
      className="inline-flex items-center text-sm gap-2"
      onClick={handleClick}
      aria-label={`${collectionStrings.collectionRemoveFilter}: ${option.title}`}
    >
      <span className="bg-filterChip-bg text-filterChip-text p-[.3125rem] rounded-full text-xs">
        <Icon id={`remove-filter-${option.slug.current}`} name="Cross" />
      </span>

      {option.title}
    </button>
  )
}

export default CollectionFilterChipValue
