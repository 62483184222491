import { useRef } from 'react'
import { createPortal } from 'react-dom'

interface InPortalProps {
  id: string
  children: JSX.Element
}

const InPortal = ({ id, children }: InPortalProps) => {
  const isFirstRender = useRef(true)

  if (isFirstRender.current) {
    isFirstRender.current = false
    return null
  }

  const element = document.querySelector(`#${id}`)

  if (!element) {
    return null
  }

  return createPortal(children, element)
}

export default InPortal
