import { type SanityFilterGroup } from '@data/sanity/queries/types/cart'
import { type SanityCollectionStrings } from '@data/sanity/queries/types/modules'
import { type UrlParameter } from '@lib/parameters'
import { type Filter, type FilterValue } from '@lib/product/types'

import Accordion from '@components/accordion'
import CollectionFilterChipValue from './filter-chip-value'

interface CollectionFilterChipsProps {
  collectionStrings: SanityCollectionStrings
  filterGroups: SanityFilterGroup[]
  activeFilters: Filter[]
  activeFilterValueCount: number
  onClick: (newParameters: UrlParameter[]) => void
  onClearClick: () => void
}

const CollectionFilterChips = ({
  collectionStrings,
  filterGroups,
  activeFilters,
  onClick,
  onClearClick,
}: CollectionFilterChipsProps) => {
  const activeFilterValues: FilterValue[] = activeFilters.flatMap(
    (activeFilter) =>
      activeFilter.values.map((value) => ({
        name: activeFilter.name,
        value,
      }))
  )

  if (!activeFilterValues.length) {
    return null
  }

  return (
    <Accordion
      id="collection-filter-chips"
      title={collectionStrings.collectionFilterResultsLabel?.replace(
        /{count}/gi,
        `${activeFilterValues.length}`
      )}
      defaultIsOpen
      className="first:border-t-0 border-divider"
      headerClassName="text-sm font-medium"
    >
      <div className="flex flex-col gap-3 overflow-x-auto no-scrollbar mb-3">
        {activeFilterValues.map((filterValue, index) => (
          <CollectionFilterChipValue
            key={index}
            collectionStrings={collectionStrings}
            filterGroups={filterGroups}
            activeFilters={activeFilters}
            filterValue={filterValue}
            onClick={onClick}
          />
        ))}
      </div>

      <button onClick={onClearClick} className="text-xs font-medium underline">
        {collectionStrings.collectionClearAllLabel}
      </button>
    </Accordion>
  )
}

export default CollectionFilterChips
