import cx from 'classnames'
import { useCallback, type ChangeEvent } from 'react'

import {
  type SanityFilterGroup,
  type SanityFilterGroupOption,
} from '@data/sanity/queries/types/cart'
import { type UrlParameter } from '@lib/parameters'
import { type Filter } from '@lib/product/types'

import { CheckboxSvg } from '@components/checkbox'
import Swatch from '@components/swatch'

interface CollectionFilterOptionProps {
  group: SanityFilterGroup
  option: SanityFilterGroupOption
  onChange: (newParameters: UrlParameter[]) => void
  activeFilter?: Filter
}

const CollectionFilterOption = ({
  group,
  option,
  onChange,
  activeFilter,
}: CollectionFilterOptionProps) => {
  const handleFilterChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!activeFilter) {
        return
      }

      const activeFilterValues = activeFilter?.values ?? []
      const newValues = activeFilterValues.includes(event.target.value)
        ? activeFilterValues.filter((value) => value !== event.target.value)
        : [...activeFilterValues, event.target.value]
      const newParameters: UrlParameter[] = [
        {
          name: activeFilter.name,
          value: newValues.length > 0 ? newValues.join(',') : null,
        },
      ]

      onChange(newParameters)
    },
    [activeFilter, onChange]
  )

  const isChecked = !!activeFilter?.values?.includes(option.slug.current)
  const isSwatch = option.type === 'swatch'

  return (
    <div className="flex flex-col relative text-left">
      <input
        id={`filter-${group.slug.current}-${option.slug.current}`}
        type="checkbox"
        name={group.slug.current}
        value={option.slug.current}
        checked={isChecked}
        onChange={handleFilterChange}
        className="sr-only"
      />

      <label
        htmlFor={`filter-${group.slug.current}-${option.slug.current}`}
        className="relative cursor-pointer inline-flex items-center text-sm gap-2"
      >
        {isSwatch && option.color && (
          <Swatch
            label={option.title}
            color={option.color}
            isActive={isChecked}
          >
            <CheckboxSvg
              isChecked={isChecked}
              className={cx(
                'absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 bg-transparent border-none',
                {
                  'bg-transparent': isChecked && isSwatch,
                }
              )}
              pathStyle={{
                color: 'var(--swatchBorder)',
              }}
            />
          </Swatch>
        )}

        {!isSwatch && <CheckboxSvg isChecked={isChecked} />}

        {option.title}
      </label>
    </div>
  )
}

export default CollectionFilterOption
